<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <div class="item">
                <span>支付宝姓名</span>
                <input type="text" placeholder="要与身份证姓名一致">
            </div>
            <div class="item">
                <span>支付宝账号</span>
                <input type="text" placeholder="请输入支付宝账号">
            </div>
        </div>
        <div class="submitBtn">
            <button>确定</button>
        </div>
        <div class="desc">
            <p>【重要】:</p>
            <p>1、支付宝必须是实名验证本人的支付宝账号</p>
            <p>2、按照支付宝验证要求，请确保填写的支付宝账号已绑定银行提现，否则无法转账</p>
            <p>3、支付宝账号必须通过支付宝的实名认证，若因此而造成不能提现，平台概不负责</p>
            <p>4、如有疑问，请联系客服</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    beforeCreate:function(){
    //判断网页是什么打开
        
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        .content{
            width: 100%;
            height: 84px;
            .item{
                height: 42px;
                border-bottom: 1px solid #ccc;
                line-height: 42px;
                span{
                    font-size: 16px;
                    display: inline-block;
                    padding:0 6px 0 16px;
                }
                input{
                    border: none;
                    font-size: 14px;
                    background-color: rgba(0, 0, 0, 0);
                    color: rgb(26, 95, 40);
                }
            }
        }
        .submitBtn{
            text-align: center;
            width: 100%;
            height: 60px;
            button{
                width: 60%;
                height: 36px;
                border: none;
                background-color: #d25555;
                color: #fff;
                border-radius: 6px;
                font-size: 18px;
                margin: 36px 0;
            }
        }
        .desc{
            font-size: 12px;
            height: 140px;
            color: #d25555;
            width: 94%;
            margin: 60px auto;
            p{
                padding: 0;
                margin: 0;
            }
        }
    }
</style>